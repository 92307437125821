<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Land'" />
              </b-col>
            </b-row> -->
            <p class="text-muted font-13 mb-0"></p>
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/landmaster`"
                title="Land"
                :columns="columns"
                routerpath="/master/crm/land/addland"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                viewComponent="LandShowView"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Village Name",
          field: "villagename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Village Name",
          },
        },
        {
          label: "Survey No. Old",
          field: "surveynoold",
          filterOptions: {
            enabled: true,
            placeholder: "Search Survey No. Old",
          },
        },
        {
          label: "Survey No. New",
          field: "surveynonew",
          filterOptions: {
            enabled: true,
            placeholder: "Search Survey No. New",
          },
        },
        {
          label: "FP No.",
          field: "fpno",
          filterOptions: {
            enabled: true,
            placeholder: "Search FP No.",
          },
        },
        {
          label: "Khata No.",
          field: "khatano",
          filterOptions: {
            enabled: true,
            placeholder: "Search Khata No.",
          },
        },
        {
          label: "Original Plot No. (OP)",
          field: "orignalplotno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Original Plot No. (OP)",
          },
        },
        {
          label: "Taluka/Tehsil",
          field: "taluka",
          filterOptions: {
            enabled: true,
            placeholder: "Search Taluka/Tehsil",
          },
        },
        {
          label: "Patwari Hulka No.",
          field: "patwarihulkano",
          filterOptions: {
            enabled: true,
            placeholder: "Search Patwari Hulka No.",
          },
        },
        {
          label: "Dist",
          field: "dist",
          filterOptions: {
            enabled: true,
            placeholder: "Search Dist",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },
         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Land" : "Add Land"}`,
        submitRouterPath: "/master/crm/land",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getlandmasterById`,
        url: `${baseApi}/landmaster`,
        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Land") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
